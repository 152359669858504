<template>
  <div class="main_footer">
    <footer class="footer_box">
      <div class="container">
        <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-12 col-xl-3">
                    <div class="mb-lg-3 mb-2">
                        <router-link to="/"> <img src="../assets/logo.png" alt="logo" class="img-fluid"> </router-link>
                    </div>
                    <div class="pe-2">
                        <p>PaisaXC helps big enterprises and startups capitalize the blockchain technology to launch secure and reliable decentralized solutions automate business.</p>
                    </div>
                       <!-- <div class="social_icons">
                            <ul class="d-flex list-unstyled align-items-center">
                                <li> <img src="../assets/images/twitter.png" alt="social-icon" class="me-3"> </li>
                                <li> <img src="../assets/images/fb.png" alt="social-icon" class="me-3"> </li>
                                <li> <img src="../assets/images/telegram.png" alt="social-icon" class="me-3"> </li>
                                <li> <img src="../assets/images/whatsapp.png" alt="social-icon" class="me-3"> </li>
                            </ul>
                        </div> -->
                </div>
        
          <div class="col" v-for="(data,index) in pages" :key="index">
            <div class="footer_box mb-4">
              <h3 class="text-uppercase">{{ nameModify(data[0].type)}}</h3>
              <ul class="list-unstyled fw-light">
                <li v-for="(item,index1) in data" :key="index1">
                   
                   <router-link :to="'/content/'+item.slug"> {{item.sub_head}}</router-link> 
                </li>
              </ul> 
            </div>
          </div>
        </div>
      </div>
    </footer>
   <div class="social-icons pt-3">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12 col-lg-12 col-xl-12">
            <div class="text-center copy-right">
              <p>
                &copy;
                <router-link to>PaisaXC.</router-link>All Rights Reserved
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ApiClass from "@/api/api";
export default {
  name: "MainFooter",
  data() {
    return {
      pages: [],
      page_load: true
    };
  },
  async mounted() {
    await this.getPages();
  },
  methods: {
    async getPages() {
      // let result = await ApiClass.getNodeRequest("list-crypto/get", false);

      let result = await ApiClass.getNodeRequest("pages/getPages", false);

      if (result.data.status_code == 1) {
        this.pages = result.data.data;
        // console.log('allllllll',this.pages);
      }
    },
    nameModify(type)
    {
        return type.replace('_',' ');
    }
  }
};
</script>

<style scoped>
.main_footer {
  background: var(--head-foot-bg);
}
footer {
  padding: 70px 0 0;
}
.footer_box p {
  color: var(--footer-grey-text);
  letter-spacing: 2px;
  font-size: 14px;
  line-height: 24px;
}

.footer_box h3 {
  font-size: 16px;
  margin-bottom: 10px;
  letter-spacing: 1px;
  color: var(--px-white);
}

.footer_box a,
.dropdown .btn_hlp {
  color: var(--footer-grey-text);
  text-decoration: none;
  font-weight: 400;
  letter-spacing: 2px;
  font-size: 13px;
}
.dropdown-menu.show {
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
  background: var(--px-black);
  color: var(--white);
  box-shadow: rgb(0 0 0 / 35%) 0px 5px 15px;
}
.dropdown-item:focus,
.dropdown-item:hover {
  color: #1e2125;
  background-color: var(--drop-hover);
}
.footer_box li {
  margin-bottom: 14px;
  transition: all 0.2s ease-in-out;
}

.footer_box a:hover {
  color: var(--px-white);
  transition: all 0.2s ease-in-out;
}

.upper_footer li,
.social-icons {
  transition: all 0.2s ease-in-out;
}

.upper_footer li img:hover,
.social-icons img:hover {
  transform: scale(1.1);
  transition: all 0.2s ease-in-out;
}

/* Copy right Link css */
.copy-right p {
  color: var(--px-white);
  font-size: 14px;
}
.copy-right a {
  color: var(--px-white);
  text-decoration: none;
  font-size: 14px;
}

.copy-right a:hover {
  color: var(--px-pink);
  text-decoration: underline;
}

/* Upper footer --> hover on  images   */
.social_icons ul li img:hover {
  transform: scale(1.1);
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}

.social_icons ul li img {
  transition: all 0.2s ease-in-out;
}

/* .footer_box h3,
.copy-right p {
    color: var(--hideous-white);
} */
@media all and (min-width: 992px) and (max-width: 1199px) {
  .reverse {
    margin-top: 10px;
  }
}
@media all and (min-width: 768px) and (max-width: 991px) {
  .reverse {
    justify-content: unset !important;
    margin-top: 10px;
  }
}
@media all and (min-width: 320px) and (max-width: 767px) {
  .reverse {
    justify-content: unset !important;
    margin-top: 10px;
  }
}
</style>

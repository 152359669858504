import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

// pagination
import Pagination from "v-pagination-3";
 
import "bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
 
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';

import { Skeletor } from "vue-skeletor";
import "vue-skeletor/dist/vue-skeletor.css";

const mixins = {
  methods: {
    toastMessage: function (icon, message) {
      this.$swal({
        title: message,
        position: "top-end",
        icon,
        toast: true,
        timer: "3000",
        showConfirmButton: false,
      });
    },
    capitalizeFirstLetter: function (string) {
      // console.log(string);
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    success: function (message) {
      this.$swal({
        title: message,
        position: "top-end",
        icon: "success",
        toast: true,
        timer: "3000",
        showConfirmButton: false,
      });
    },
    failed: function (message) {
      this.$swal({
        title: message,
        position: "top-end",
        icon: "error",
        toast: true,
        timer: "3000",
        showConfirmButton: false,
      });
    },

    formatDate: function (date) {
      var u = new Date(date);

      return (
        u.getUTCFullYear() +
        "-" +
        ("0" + (u.getUTCMonth() + 1)).slice(-2) + // Adding 1 to the month
        "-" +
        ("0" + u.getUTCDate()).slice(-2) +
        " " +
        ("0" + u.getUTCHours()).slice(-2) +
        ":" +
        ("0" + u.getUTCMinutes()).slice(-2) +
        ":" +
        ("0" + u.getUTCSeconds()).slice(-2)
      );
    },

    priceConversion(nowPrice, priceType, rate, btcPrice) {
      if (priceType == "inr") {
        return (nowPrice * rate).toFixed(4);
      }
      else if (priceType == "btc") {
        return (nowPrice / btcPrice).toFixed(4);
      }
    }
  },
};

createApp(App)
  .component(Skeletor.name, Skeletor)
  .component("pagination", Pagination).use(VueSweetalert2)
  .use(store)
  .use(router)
  .mixin(mixins)
  .mount("#app");
 

 

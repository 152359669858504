<template>
    <div>
        <div class="col-12" id="market">
            <div class="market_status d-md-flex d-block justify-content-between">
                <div class="mb-3">
                    <p class="mb-0">In the Past <span>24</span> hours</p>
                    <h3>Market is {{marketCap > 0 ?'up':'down'}} by <span
                            :style="marketCap > 0 ? 'color:var(--green)': 'color:var(--px-red)' ">
                            {{marketCap.toFixed(2)}}%</span></h3>
                </div>

                <!-- <div>
                <div class="input-group mb-3">
                    <span class="input-group-text border-0" id="basic-addon1">
                        <img src="../../assets/images/icons/search.svg" alt="icon" class="img-fluid" />
                    </span>
                    <input type="text" class="form-control border-0" placeholder="Search" aria-label="Username" aria-describedby="basic-addon1" />
                </div>
            </div> -->
                <div>
                    <div class="dropdown">
                        <!-- <button class="btn p-0 dropdown-toggle shadow-none" type="button"  > -->
                        <!-- data-bs-toggle="dropdown"
                            aria-expanded="false" -->

                        <!-- </button> -->
                        <div class="input-group">
                            <span class="input-group-text border-0 shadow-none" id="basic-addon1">
                                <img src="../../assets/images/icons/search.svg" alt="icon" class="img-fluid" />
                            </span>
                            <input @keyup="changed_val()" type="text" class="form-control border-0 shadow-none"
                                placeholder="Search" v-model="search" aria-label="Username"
                                aria-describedby="basic-addon1" />
                            <ul v-if="show" class="dropdown-menu mt-1">

                                <li class="no_data py-4" v-if="filterdata.length == 0">
                                    <p class="text-uppercase mb-0">NO data found</p>
                                </li>

                                <li v-else v-for="d in filterdata" :key="d.id" style="cursor: pointer;">
                                    <div class="search-inner-box">
                                        <span @click="$router.push('exchange?s='+d.symbol)" to="/exchange">

                                            <span class="img-box">
                                                <img :src="d.image" alt="" style="width:18px; height:18px;" />
                                            </span>
                                            {{d.currency}}/{{d.pair_with}}
                                        </span>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <!-- ____________Top Gainer________________ -->
            <!-- <div class="col-xl-6 col-xxl-3 col-lg-6 col-md-5" v-for="i in 4" :key="i">
         <div class="market_box w-100 mb-5 d-flex gap-4">
           <div class="w-100" > <Skeletor  height="145 "/></div>
        </div>
    </div> -->


            <div v-show="mrkt_loader" class="col-xl-6 col-xxl-3 col-lg-6 col-md-5" v-for="i in 4" :key="i">
                <div class="market_box mb-5">
                    <div class="status_box">
                        <Skeletor v-show="mrkt_loader" height="145" />
                    </div>
                </div>
            </div>

            <div v-show="!mrkt_loader" class="col-xl-6 col-xxl-3 col-lg-6 col-md-5" v-for="(data, index) in marketData"
                :key="index">

                <!-- <div class="market_box mb-5">
                <div class="status_box">
                     <Skeletor  height="145" />
                </div>
            </div> -->

                <div class="market_box mb-5">
                    <div class="m_heading mb-4 text-center">
                        <h4 class="text-uppercase">{{ data.heading }}</h4>
                    </div>
                    <div class="status_box d-flex align-items-center ">
                        <div class="market_image ps-1">
                            <img :src="data.image" alt="image" class="img-fluid" style="height:50px;" />
                        </div>
                        <div class="top_gainer px-3 py-3">
                            <h3>{{data.name }}</h3>
                            <h3>
                                <span> {{data.data }} </span>
                            </h3>
                            <p class="mb-0">{{ data.subhead }}</p>
                        </div>
                    </div>
                </div>

            </div>
            <!-- __________________TRADE TABLE________________________ -->
            <div class="col-md-12">
                <div class=" mt-5">
                    <div class="trade_table">
                        <div class="table_header d-md-flex d-block justify-content-between align-items-center">
                            <ul class="nav nav-pills mb-4" id="pills-tab" role="tablist">
                                <li class="nav-item" role="presentation" v-for="(tab,i) in Tabs" :key="i">
                                    <button class="nav-link" :class="i==0?'active':''" @click="changeTab(tab.tab)"
                                        id="pills-coin-tab" data-bs-toggle="pill" data-bs-target="#pills-coin"
                                        type="button" role="tab" aria-controls="pills-coin" aria-selected="false">
                                        <img :src="tabImage(tab.img)" style="width:20px;">
                                        {{tab.tab}}</button>
                                </li>
                            </ul>

                            <!-- <div class="btn-toolbar mb-3" role="toolbar" aria-label="Toolbar with button groups">
                        <div class="btn-group cus-border" role="group">
                            <button type="button" class="time_btn">1H</button>
                            <button type="button" class="time_btn">1D</button>
                            <button type="button" class="time_btn">1W</button>
                            <button type="button" class="time_btn">1M</button>
                            <button type="button" class="time_btn border-end-0">1Y</button>
                        </div>
                    </div> -->

                        </div>

                        <div class="tab-content" id="pills-tabContent">
                            <div class="tab-pane fade show active" id="pills-coin" role="tabpanel"
                                aria-labelledby="pills-coin-tab">
                                <div class="table-content p-4">
                                    <div class="table-responsive">
                                        <table class="table table-borderless">
                                            <thead>
                                                <tr>
                                                    <th scope="col" class="ps-4">Name</th>
                                                    <th scope="col">Last Price</th>
                                                    <th scope="col"> </th>  <!---For chart -->
                                                    <th scope="col">24h Change</th>
                                                    <!-- <th scope="col">Market Cap</th> -->
                                                    <th scope="col">Volume (24h)</th>
                                                    <!-- <th scope="col">Supply</th> -->
                                                    <th scope="col" class="text-center">Buy</th>

                                                </tr>
                                            </thead>

                                            <tbody v-if="tab_loader">
                                                <tr v-for="i in 5" :key="i">
                                                    <td class="ps-4">
                                                        <Skeletor />
                                                    </td>
                                                    <td>
                                                        <Skeletor />
                                                    </td>
                                                    <td>
                                                        <Skeletor />
                                                    </td>
                                                    <td>
                                                        <Skeletor />
                                                    </td>
                                                    <td class="text-center">
                                                        <Skeletor />
                                                    </td>
                                                </tr>
                                            </tbody>

                                            <tbody v-else-if="!tab_loader && allTabdata.length == 0">
                                                <tr>
                                                    <td colspan="5" class="text-center py-3 ">No Record Found</td>
                                                </tr>
                                            </tbody>

                                            <tbody v-else>
                                                <tr v-for="(data,index) in allTabdata" :key="index">
                                                    <td class="ps-4" v-if="current_tab == 'All Coins'">{{data.name}} /
                                                        <span>{{data.currency}}</span>
                                                    </td>
                                                    <td class="ps-4" v-else>{{data.currency}} /
                                                        <span>{{data.pair_with}}</span>
                                                    </td>
                                                    <td>{{data.price}} {{data.pair_with}}</td>
                                                    <td>
                                                        <img :src="data.chart" style="height:50px;" alt="chart.svg">
                                                    </td>
                                                    <td
                                                        :style="data.change > 0 ? 'color:var(--green)': 'color:var(--px-red)' ">
                                                        {{data.change}} % </td>
                                                    <td>{{parseFloat(data.volume)}}</td>
                                                    <td class="text-center"><button type="button"
                                                            @click="btnclick(data.symbol)" class="btn_buy">Buy</button>
                                                    </td>

                                                </tr>
                                                <tr class="text-center">
                                                    <td colspan="8">
                                                        <div class="pt-2 py-1">
                                                            <router-link to="/marketview"> <button type="button"
                                                                    class="px_btn mb-3">View All
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="14"
                                                                        height="14" style="fill: var(--white)"
                                                                        viewBox="0 0 320 512">
                                                                        <path
                                                                            d="M285.476 272.971L91.132 467.314c-9.373 9.373-24.569 9.373-33.941 0l-22.667-22.667c-9.357-9.357-9.375-24.522-.04-33.901L188.505 256 34.484 101.255c-9.335-9.379-9.317-24.544.04-33.901l22.667-22.667c9.373-9.373 24.569-9.373 33.941 0L285.475 239.03c9.373 9.372 9.373 24.568.001 33.941z" />
                                                                    </svg>
                                                                </button> </router-link>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
    import ApiClass from "@/api/api";
    import _ from "lodash";
    export default {
        name: 'CalculaterView',
        props: {
            crypto: Object,
            s_data: Object
        },
        data() {
            return {
                search: '',
                show: false,
                marketCap: 0,
                marketData: [],
                topGainers: [],
                topLoosers: [],
                tradeables: [],
                newListings: [],
                allCoins: [],
                allTabdata: [],
                Tabs: [
                    { 'tab': 'All Coins', img: '' },
                    { 'tab': 'Tradable', img: '' },
                    { 'tab': 'Gainers', img: 'green-up-arrow.svg' },
                    { 'tab': 'Losers', img: 'red-down-arrow.svg' },
                ],
                current_tab: 'All Coins',
                tab_loader: true,
                mrkt_loader: true,
            }
        },
        computed: {
            filterdata() {
                return this.s_data.filter((d) => {
                    return d.currency.toLowerCase().indexOf(this.search.toLowerCase()) != -1;
                })
            }
        },
        mounted() {
            this.getMarketcap();
        },
        methods: { 
            arrangeData(d){
                let final = []
                d.map((el) =>{
                    let d = []
                    d.value = el[4];
                    d.time = el[0]/1000

                    final.push(d);
                    

                    return el;
                })

                // console.log('final==',final)
                return final;
            },
            changed_val() {
                (this.search.length > 0) ? this.show = true : this.show = false;
            },
            async getMarketcap() {
                let res = await ApiClass.getNodeRequest('dashboard/market-cap')
                if (res.data.status_code == 1) {
                    this.marketCap = res.data.data.cap ? res.data.data.cap : ''
                }
            },
            tabImage(img) {
                if (img != '') {
                    return require(`@/assets/images/icons/${img}`)
                }
            },
            changeTab(tab) {
                this.current_tab = tab
                if (tab == 'Tradable') {
                    this.allTabdata = this.tradeables.slice(0, 5)
                }
                if (tab == 'Gainers') {
                    this.allTabdata = this.topGainers.slice(0, 5)
                }
                if (tab == 'Losers') {
                    this.allTabdata = this.topLoosers.slice(0, 5)
                }
                if (tab == 'All Coins') {
                    this.allTabdata = this.allCoins.slice(0, 5)
                }
            },
            btnclick(symb) {
                this.$router.push({ path: "/exchange", query: { s: symb } });
            }
        },
        watch: {
            crypto: {
                async handler(newValue) {
                   
                    let $this = this;
                    this.tab_loader = true;
                    this.mrkt_loader = true;
                    // console.log('before==',this.mrkt_loader)
                    // await new Promise(resolve => setTimeout(resolve, 1000)); //set promise for sk loader
                    let pair = newValue;
                    this.tab_loader = false;

                    let ticker = pair.tickers; //for socket
                    let allData = _.concat(pair.data.BNB, pair.data.BTC, pair.data.ETH, pair.data.USDT); //concat all crypto
                    this.allCoins = this.allTabdata = allData.slice(0, 5) //show 5 crypto on first tab


                    // console.log('ALL',this.allCoins)
                    // console.log('other==', allData)

                    this.topGainers = _.orderBy(allData, item => parseFloat(item.change), ['desc']);
                    // console.log('topGainers',this.topGainers)

                    this.topLoosers = _.orderBy(allData, item => parseFloat(item.change), ['asc']);
                    // console.log('topLoosers',this.topLoosers)

                    this.tradeables = _.orderBy(allData, item => parseFloat(item.volume), ['desc']);
                    // console.log('tradeables',this.tradeables)

                    this.newListings = _.orderBy(allData,['created_at'], ['desc'])


                    this.mrkt_loader = false;
                    // console.log('after==', this.mrkt_loader)

                    this.marketData = [
                        {
                            heading: "Top Gainer",
                            subhead: "Price change",
                            data: this.topGainers[0].change + '%',
                            image: this.topGainers[0].image,
                            name: this.topGainers[0].name,
                        },
                        {
                            heading: "New Listing",
                            subhead: "Added",
                            data: new Date(this.newListings[0].created_at).toLocaleString('default', { month: 'long', day: 'numeric' }),
                            image: this.newListings[0].image,
                            name: this.newListings[0].name,
                        },
                        {
                            heading: "Top Loser",
                            subhead: "Price change",
                            data: this.topLoosers[0].change + '%',
                            image: this.topLoosers[0].image,
                            name: this.topLoosers[0].name,
                        },
                        {
                            heading: "Most Traded",
                            subhead: "Volume (24h)",
                            data: this.tradeables[0].volume,
                            image: this.tradeables[0].image,
                            name: this.tradeables[0].name,
                        },
                    ]

                    ////socket hit
                    let subs = ticker.map((v) => v.toLowerCase() + "@ticker");
                    const data = {
                        method: "SUBSCRIBE",
                        params: subs,
                        id: 1,
                    };
                    const connection = new WebSocket(process.env.VUE_APP_BINANCE_WEBSOCKET_ENDPOINT);
                    connection.onopen = function () {
                        connection.send(JSON.stringify(data));
                    };
                    connection.onmessage = function (event) {
                        var msg = JSON.parse(event.data);

                        $this.allTabdata.filter((list) => {
                            if (list.symbol == msg.s) {
                                list.change = msg.P;
                                list.m = parseFloat(list.price) > parseFloat(msg.c) ? true : false;
                                list.price = msg.c;
                            }
                        });
                    };
                }
            },

        }
    }
</script>

<style scoped>
    .market_status .input-group-text {
        background-color: transparent;
    }

    .market_status .input-group {
        border: 1px solid var(--border);
    }

    .market_status h3 span {
        color: var(--px-red);
        font-family: "Poppins", sans-serif;
    }

    .market_status h3 {
        font-size: 25px;
    }

    .market_status p span {
        font-weight: 400;
        font-family: "Poppins", sans-serif;
        color: var(--span);
    }

    .m_heading h4 {
        font-size: 16px;
        font-weight: 500;
    }

    .market_status p {
        color: var(--light-text);
        font-size: 14px;
    }

    .top_gainer p {
        color: var(--light-text);
        font-size: 15px;
        font-family: "Poppins", sans-serif;
    }

    .top_gainer {
        position: relative;
    }

    .top_gainer h3 {
        font-size: 18px;
    }

    .top_gainer h3 span {
        color: var(--px-purple);
        font-family: "Poppins", sans-serif;
        font-size: 17px;
    }

    .market_image {
        position: relative;
    }

    .market_image::before {
        position: absolute;
        content: "";
        background-image: url(../../assets/images/border-img.png);
        width: 285px;
        height: 130px;
        top: 7px;
        /* left: 29px; */
        left: 33px;
        right: 0;
        bottom: 0;
        margin: auto;
        background-repeat: no-repeat;
    }

    /* trade table */

    .time_btn {
        color: var(--trade-time);
        text-align: center;
        cursor: pointer;
        background-color: transparent;
        border: 1px solid transparent;
        border-right: 1px solid var(--trade-border);
        padding: 0.375rem 0.75rem;
        font-size: 12px;
        border-radius: 0;
        font-family: 'Poppins', sans-serif;
    }

    .tab_heads span {
        font-weight: 400 !important;

    }

    .px_btn {
        color: var(--white);
        cursor: pointer;
        background: linear-gradient(90deg, rgba(253, 1, 148, 1) 3%, rgba(235, 13, 123, 1) 62%, rgba(153, 0, 177, 0.8939950980392157) 79%);
        border: 1px solid transparent;
        font-size: 14px;
        border-radius: 20px;
        transition: all 0.2s ease-in-out;
        padding: 10px 25px;
    }

    .cus-border {
        border: 1px solid var(--trade-border);
    }

    .trade_table .nav-pills .nav-link.active,
    .nav-pills .show>.nav-link {
        color: var(--px-pink);
        background-color: transparent;
        border-bottom: 2px solid var(--px-pink);

    }

    .trade_table .nav-pills .nav-link {
        border-radius: 0;
        font-weight: 400;
        margin-bottom: 10px;
        font-family: 'poppins', sans-serif;

    }

    .trade_table .nav-link {
        padding: 0 1rem;
        color: var(--black);
    }

    .table-content {
        background-color: var(--off-white);
    }

    tbody tr td {
        background-color: var(--white);
        font-size: 14px;
        font-weight: 500;
    }

    tbody tr td span {
        color: var(--trade-text);
        font-size: 12px;
    }

    thead tr th {
        color: var(--light-text);
        font-size: 15px;
        font-weight: 500;

    }

    .btn_buy {
        background-color: var(--green);
        border: 1px solid var(--green);
        color: var(--white);
        font-size: 14px;
        border-radius: 3px;
        padding: 3px 20px;
    }

    table {
        white-space: nowrap;
        font-family: 'Poppins', sans-serif;
    }

    /* NEW CSS */
    .dropdown-toggle::after {
        display: inline-block;
        content: "";
        border-top: 0;
        border-right: 0 transparent;
        border-bottom: 0;
        border-left: 0 transparent;
    }

    .dropdown-item.active,
    .dropdown-item:active {
        color: var(--px-black);
        text-decoration: none;
        background-color: var(--px-white);
    }

    .dropdown-menu {
        min-width: 265px;
    }

    .dropdown-item {
        color: var(--px-black);
        font-size: 14px;
    }

    ul.dropdown-menu {
        display: block;
        transform: translate(0px, 45px) !important;
        overflow-y: scroll;
        height: 210px;
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    }

    .no_data {
        font-size: 15px;
        text-align: center;
        height: 135px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .no_data p {
        color: var(--px-black) !important;
        font-weight: 500;
    }


    ul.dropdown-menu {
        padding: 10px 10px;
    }

    ul.dropdown-menu li:hover {
        background-color: var(--light-grey);
    }

    @media (min-width: 1200px) and (max-width: 1399px) {
        .market_image::before {
            background-image: url(../../assets/images/smallborder-img.png);
            top: 20px;
            left: 32px;
        }

        .m_heading h4 {
            text-align: start;
        }
    }

    @media (min-width: 992px) and (max-width: 1199px) {
        .banner-left::before {
            display: none;
        }
    }

    @media (min-width: 768px) and (max-width: 991px) {
        .top-gain {
            justify-content: center;
        }
    }

    @media (min-width: 320px) and (max-width: 767px) {
        .market_image::before {
            width: 251px;
            background-image: url(../../assets/images/smallborder-img.png);
            top: 18px;
            left: 34px;
        }
    }
</style>
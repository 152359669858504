<template>
    <div class="app_view">
        <MainHeader :key="update" v-model:metaR.sync="rval"  v-model:rname="rname"/>
        <router-view />
        <MainFooter v-if = "current_route" />
    </div>
</template>

<script>
import MainHeader from "@/components/MainHeader.vue";
import MainFooter from "@/components/MainFooter.vue"
import ApiClass from "@/api/api";
export default {
    components: {
        MainHeader,
        MainFooter
    },
    data() {
        return {
        update:'',
        rval :'',
        rname:'',
        current_route: true
    }},
    mounted() {
        this.nodeHit();
    },
    methods: {
        async nodeHit() {
        var data = await ApiClass.getNodeRequest("user/get/authority", false);
        var arr = data.data.data?.find(
            (element) => element.type == "maintenance"
        );

        if (arr) {
            this.$store.commit("initialMaintenance", arr.status);
        }

        var socketConnection = new WebSocket(ApiClass.nodeWebsocket);

        var Streamer = socketConnection;
        var $this = this;
        socketConnection.onopen = function onStreamOpen() {
            Streamer.onmessage = function (event) {
            var status = JSON.parse(event.data).status;
            if (status) {
                $this.$store.commit("initialMaintenance", status);
                if (status == "on") {
                $this.maintenanceInt = setInterval(() => {
                    $this.$store.commit("initialMaintenance", arr.status);
                    if ($this.$router.currentRoute.path != "/maintenance") {
                    localStorage.clear();
                    window.location.href =
                        window.location.origin + "/maintenance";
                    }
                }, 1000);
                //   $this.$router.push("/maintenance");
                } else {
                clearInterval($this.maintenanceInt);
                $this.$router.push("/");
                }
            }
            };
        };
        },
    },
    watch: {
        '$route': function (v) {
            // console.log('vvv', v)
            this.update = v;
            this.rval = v.meta.routen;
            this.rname = v.name;

            // console.log('rname===', this.rname)

            if(v.path == '/'){
                this.current_route = true;
            }else{
                this.current_route = false;
            }
            

            // console.log('current_route', this.current_route)
        }
    },
}
</script>

<style lang="scss"> 
/* // FONT FAMILY // */
@font-face {
     font-family: 'Raleway';
    src: url(../src/assets/font-family/Raleway-ExtraBold.ttf);
    font-weight: 800;
}

@font-face {
     font-family: 'Raleway';
    src: url(../src/assets/font-family/Raleway-Bold.ttf);
    font-weight: 700;
}

@font-face {
     font-family: 'Raleway';
    src: url(../src/assets/font-family/Raleway-SemiBold.ttf);
    font-weight: 600;
}

@font-face {
     font-family: 'Raleway';
    src: url(../src/assets/font-family/Raleway-Medium.ttf);
    font-weight: 500;
}

@font-face {
    font-family: 'Raleway';
    src: url(../src/assets/font-family/Raleway-Regular.ttf);
    font-weight: 400;
}

// POPPINS FONT FAMILY   font-family: 'Poppins', sans-serif;
@font-face{
    font-family: 'Poppins';
    src: url(../src/assets/font-family/Poppins/Poppins-Regular.ttf);
    font-weight: 400;
    
}
@font-face{
    font-family: 'Poppins';
    src: url(../src/assets/font-family/Poppins/Poppins-Medium.ttf);
    font-weight: 500;
}
@font-face{
    font-family: 'conthrax';
    src: url(../src/assets/font-family/conthrax/conthrax-sb.ttf);
    font-weight: 400;
}



#app{
    font-family: 'Raleway', sans-serif  !important;
}

:root {
    --error-red:#ff0000;
    --off-white: #F3F3F3;
    --px-grey: #686868;
    --light-text: #A7A7A7;
    --light-grey: #E8E5E5;
    --text-grey: #919191;
    --px-black: #000;
    --px-purple: #3B0C58;
    --banner-grey:#E2E2E2;
    --span:#6A6A6A;
    --image-light:#686975;

    --px-primary: #101021;
    --px-white: #fff;
    --copy-white: #fff;
    --white: #fff;
    --px-pink: #ff006f;
    --px-red: #e50e0e;
    --light-pink:#fe0191;
    --green: #44BD22;

    --border: #cfccd1;
    --dark-purple: #140620;
    --footer-grey-text: #bfbfbf;

    /*PORTFOLIO SETTING PAGE  */
    --setting-bg: #111224;
    --setting-border: #393A49;
    --ebony-clay: #2B2C3E;
    --ebony-clays: #2B2C3E;
    --setting-inner-box: #1E1F31;
    --input-bg: #262739;
    --two-f-bg: #262739;
    --grey-border: #5F606D;
    --light-border: #cfccd11f;
    --next-btn: linear-gradient(105deg, rgba(239, 2, 85, 1) 84%, rgba(255, 0, 99, 1) 95%, rgba(255, 0, 111, 1) 100%);
    --setting-btn: linear-gradient(150deg, rgba(255, 0, 126, 1) 6%, rgba(255, 0, 99, 1) 36%, rgba(255, 0, 86, 1) 43%);
    /*INR PAGE VAR */
    --inr-green: #44BD22;
    --inr-btn-green: #0C881D;
    --table-grey: #C4C4C4;
    --inr-modal-bg: #1B1C26;
    --inr-modal-box: #262730;
    --inr-purple: #3C1C33;
    --referral-input-bg: #191A2C;

    /* MODAL */
    --modal-bg: #181923;
    --modal-border: #393a4970;
    --modal-green: #34A84E;

    --auth-bg: url('@/assets/images/Auth/background_img.png');
    --auth-box: rgba(255, 255, 255, 0.1);
    --auth-light-pink: #FF006F;
    --auth-light-border: #fff;

    /***************PORTFOLIO***************/
    --portfolio-bg: #080910;
    --px-table-hover: #1e1f31;
    --table-bg: #111224;
    --table-green: #0c881d;
    --table-orange: #fa652d;
    --row-hover: #1e1f31;
    --table-grey: #b7b7b7;
    --modal-input: #262739;
    --accordian-color: #1a1b22;

    // EXCHANGE PAGE VAR
    --px-dark-grey: #b9b9b9;
    --px-background: #111224;
    --px-border: #323238;

    --red: #E40909;
    --red-btn: #FF0034;
    --trade-red: #FA652D;
    --green-bg: #162A19;
    --red-bg: #311D1B;
    // P2P / 
    --p2p-input: #191a2c;
    --p2p-modal: #25262e;
    --p2p-modal-btn: #34A84E;
    --p2p-modal-bg: #1E1F31;
    --p2p-modal-body: #171820;
    --p2p-progress: #31334c8a;
    --p2p-copy-modal: #262739;

    --readonly-bg: #2D2E40;
    // exchange page /
    --p2p-ex-input: #07081a;
    --px-exch-modal: #1c1e26;
    --black: #000;
    --pill-tab: #252530;
    --transparent: transparent;
    --drop-bg: #2F3043;
    --cut-img: url(../src/assets/images/close.png);
    --buy-sell: #202133;
    --arrow-svg: url('@/assets/images/icons/caret-right.svg');
    --seller-img: url('../src/assets/images/p2p/image2.png');

    --step-list-white: #fff;
    --list-background: #111224;
    --list-white: #fff;
    --arrow-down: url('assets/images/icons/chavron-white-down.svg');
    --drop-hover: #18192C;

    // / future exchange /
    --btn-fexchange: #202133;
    --input-bg: #2D2E40;
    --input-border: #454655;
    --future-badge: #2B2C3E;
    --px-slider: #fff;
    --inr-grey: #ccc;
    --buy-sell-chart: #151628;
    --head-foot-bg: #080910;
    --trade-time:#929292;
    --trade-border:#707070;
    --trade-text:#717171;
    --intro-grey:#CCCBCB;
    --trust_grey:#545454;
   
    --scroll-fill:#323238;
    --ebony-clays-active:#2B2C3E;
    --fav-star:#ff006f;
    --small-star:#929292;


    /** STAKING **/
    --staking-modal:#1B1C24;  
    --staking-modal-input:#24262E; 
    --stake-white:#fff;
    --black-land:#000;
    --light-text1: #A7A7A7;
    --spot-trade-history:#111224;

}

.light {
    --light-text1: #000;
    --scroll-fill: #cfccd1;
    --px-black: #fff;
    --px-white: #000;
     --image-light:#000;

    --auth-bg: #fff;
    --auth-box: #fff;
    --px-primary: #F3F3F3;
    --auth-light-pink: #ff006f26;
    --auth-light-border: #ff006f26;
    --footer-grey-text: #646161;
    --px-background: #fff;
    --px-border: #ccc;
    --green-bg: #44bd2242;
    --red-bg: #FACBBA;
    --px-dark-grey: #000;
    --pill-tab: #F1F1F1;

    --p2p-input: #E4E4E4;
    --transparent: #E4E4E4;
    --p2p-modal-bg: #F3F3F3;
    --p2p-ex-input: #C4C4C4;

    --readonly-bg: #fff;
    --ebony-clay: #DEDEDE;
    --setting-border: #fff;

    --drop-bg: #1E1E1E;
    --setting-inner-box: #DBDBDB;
    --portfolio-bg: #fff;
    --setting-bg: #F9F9F9;
    --accordian-color: #E6E6E6;
    --ebony-clays: #fff;
    --modal-bg: #F2F2F2;
    --modal-border: #F2F2F2;
    --cut-img: url(../src/assets/images/block-close.png);
    --buy-sell: #EEEEEE;
    --px-exch-modal: #F2F2F2;
    --arrow-svg: url('@/assets/images/icons/caret-right-w.svg');
    --step-list-white: #DBDBDB;
    --list-background: #DBDBDB;
    --p2p-progress: #fff;
    --p2p-copy-modal: #e4e4e4;
    --p2p-modal: #F2F2F2;
    --p2p-modal-body: #E3E3E3;
    --input-bg: #DBDBDB;
    --setting-border: #dbdbdbcc;
    --setting-inner-box: #F3F3F3;
    --list-white: #DBDBDB;
    --arrow-down: url('assets/images/icons/chavron-white-down-b.svg');
    --text-grey: #000;
    --grey-border: #d9d9d9cc;
    --two-f-bg: #fff;
    --row-hover: #E5E5E5;
    --table-bg: #f9f9f9;
    --copy-white: #FF006F;
    --inr-modal-bg: #EFEFEF;
    --inr-modal-box: #E1E1E1;
    --inr-purple: #F9569D;
    --drop-hover: #9f9f9fbd;
    --drop-bg: #f9f9f9;
    --btn-fexchange: #f3f3f3;
    --future-badge: #dedede;
    --input-border: #dedede;
    --px-slider: #FF006F;

    --inr-grey: #443f3f;
    --buy-sell-chart: #DBDBDB;
    --table-grey: #151628;

    --head-foot-bg: #F9F9F9;
    --ebony-clays-active: #E4E4E4;

        /** STAKING LIGHT**/
        --staking-modal:#f2f2f2;
    --staking-modal-input:#dbdbdb52;
    --stake-white:#dee2e6;
    --spot-trade-history:#fff;
}
.multiselect-dropdown{
    background-color: var(--p2p-input) !important;
    border: 1px solid var(--px-border);
    color:var(--px-white) !important;
}
.multiselect-option.is-pointed {
    background: var(--p2p-input) !important;
    color: var(--px-white) !important;
} 
.multiselect-option span{
    color:var(--px-white) !important;
}
.swal2-popup {
    background: #fff;
    color: var(--px-black);
    font-size: 14px;
}
h2#swal2-title{
   color: var(--px-black); 
   font-size: 18px; 
}
button.swal2-confirm.swal2-styled.swal2-default-outline ,
button.swal2-cancel.swal2-styled.swal2-default-outline{
    background-color: var(--px-pink) !important;
}
// ERROR CLASS
.error_msg{
    color: var(--error-red);
    font-size: 13px;
    font-weight: 500;
    font-family: 'Poppins' !important;
}
.app_view{
    height: 100vh;
    background-color: var(--head-foot-bg) !important;
}

/* Poster swiper section css */
.swiper-pagination {
    position: unset !important;
}

.swiper-pagination-bullet {
    border-radius: 0 !important;
}

/* Change the white to any color */

.profile_box input:-webkit-autofill,
.profile_box input:-webkit-autofill:hover, 
.profile_box input:-webkit-autofill:focus, 
.profile_box input:-webkit-autofill:active{
    -webkit-box-shadow: 0 0 0 30px var(--input-bg) inset !important;
    -webkit-text-fill-color: var(--px-white) !important;
}


.swiper-pagination-bullet-active {
    background: var(--swiper-pagination-color, var(--black)) !important;
}
// .swiper-slide {
//     width: auto !important;
// }

/* SETTING MODULE COMMON CSS */
/* BACK ANF NEXT BUTTON CSS */
.btn_next {
    color: var(--white);
    cursor: pointer;
    border: 1px solid var(--px-pink);
    font-size: 14px;
    border-radius: 0.25rem;
    background: var(--next-btn);
    padding: 8px 8px;
    width: 180px;
    font-weight: 500;
}

.btn-check:focus+.btn,
.btn:focus {
    box-shadow: none;
}

.btn_back {
    color: var(--px-white);
    cursor: pointer;
    background-color: transparent;
    border: 1px solid var(--setting-border);
    font-size: 14px;
    border-radius: 0.25rem;
    padding: 8px 8px;
    width: 180px;
    font-weight: 500;
}

.btn_back a {
    color: var(--white);
    text-decoration: none;
}

.btn_back a:hover {
    color: var(--px-white);
}

.btn_setting {
    color: var(--px-white);
    cursor: pointer;
    border: 1px solid var(--px-pink);
    font-size: 14px;
    border-radius: 0.25rem;
    background: var(--setting-btn);
    padding: 8px 8px;
    width: 180px;
}

/************************************Pagination*************************************/

.pagination {
    justify-content: end !important;
     font-family: 'poppins', sans-serif;
}

.page-link {
    z-index: 3 !important;
    color: var(--px-white) !important;
    background-color: transparent !important;
    border-color: var(--px-pink) !important;
    box-shadow: none !important;
}

.page-item.active .page-link {
    z-index: 3 !important;
    color: var(--white) !important;
    background-color: var(--px-pink) !important;
    border-color: var(--px-pink) !important;
    box-shadow: none !important;
}

.pagination_box ul li {
    border: none !important;
    margin-bottom: 20px !important;
    /* border-radius: yellow !important;  */
    margin-bottom: 0 !important;
    color: var(--px-white);
}

.pagination_box p {
    font-size: 14px;
    color: var(--px-white);
}

.page-item:first-child .page-link,
.page-item:last-child .page-link {
    box-shadow: none !important;
}
/* Error */
.error_msg{
    color: var(--error-red);
}
/* AUTH PAGES */
.auth_page {
    background-color: var(--px-primary);
    min-height: 100vh;
    background-image: var(--auth-bg);
    display: flex;
    justify-content: center;
    align-items: center;
}

.auth_page .auth_box {
    background: var(--auth-box);
    border-radius: 15px;
    backdrop-filter: blur(4px);
    border: 1px solid var(--auth-light-pink);
}

.auth_page .main_headings h2 {
    font-size: 34px;
    font-weight: 600;
    color: var(--px-white);
}

.auth_page .main_headings h2 span {
    font-size: 34px;
    font-weight: 600;
    color: var(--px-pink);
}

.auth_page .main_headings h3 {
    font-size: 14px;
    font-weight: 500;
    color: var(--px-dark-grey);
}

.form_box label {
    font-size: 14px;
    font-weight: 600;
    color: var(--px-white);

}

.form_box .form-control {
    border: 0;
    padding: 9px 9px;
    background-color: transparent;
       font-size: 13px;

}

.form_box .form-control:focus {
    border: none;
}

.form_box .input-group {
    border-radius: 4px;
    border: 1px solid var(--auth-light-border);
    background-color: var(--white);
   font-family: 'poppins', sans-serif;
}

.auth_box ::placeholder {
    color: var(--text-grey);
   
}

.input-group .input-group-text {
    background-color: transparent;
    border: 0;

}

.auth_btn .btn-auth {
    background-color: var(--px-pink);
    color: var(--white);
    border-radius: 5px;
    font-weight: 600;
    font-size: 14px;
    border: 1px solid var(--px-pink);
    padding: 0.375rem 0.75rem;
}

// .btn:hover {
//     background-color: transparent;
//     color: var(--px-white) !important;
// }

.info_box p {
    color: var(--px-dark-grey);
    font-size: 14px;
}

.info_box a {
    color: var(--px-pink);
    font-size: 14px;

    text-decoration: none;
    font-weight: 600;
}

.info_box a:hover {
    color: var(--px-pink);
    text-decoration: underline;
}

/* / otp / */
input.otp-input {
    height: 60px !important;
    width: 65px !important;
    border-radius: 10px !important;
    text-align: center;
}

/* / loader / */
.auth_btn .btn-auth .spinner-border {
    height: 23px;
    width: 23px;
}

/*=================p2p================ */
.p2p_heading {
    border-bottom: 1px solid var(--px-border);
}

.p2p_heading h5 {
    font-size: 14px;
    font-weight: 600;
    color: var(--px-white);
}

.main_box {
    border: 1px solid var(--px-border);
    border-radius: 5px;
    background-color: var(--p2p-modal-bg);
}

.main_heading {
    border-bottom: 1px solid var(--px-border);
}
/*****************skeltor loader css */

.vue-skeletor {
  position: relative;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.12);
}

.vue-skeletor:not(.vue-skeletor--shimmerless):after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transform: translateX(-100%);
  background-image: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 0.3),
    rgba(37, 22, 22, 0)
  );
  animation: shimmer 1.5s infinite;
  content: "";
}

.vue-skeletor--rect,
.vue-skeletor--circle {
  display: block;
}

.vue-skeletor--circle {
  border-radius: 50%;
}

.vue-skeletor--pill,
.vue-skeletor--text {
  border-radius: 9999px;
}

.vue-skeletor--text {
  line-height: 1;
  display: inline-block;
  width: 100%;
  height: inherit;
  vertical-align: middle;
  top: -1px;
}
.error-class{
    color: var(--red) ;
    font-size: 12px;
    font-weight: 500;
}

@keyframes shimmer {
  100% {
    transform: translateX(100%);
  }
}

/*****************skeltor loader css *******/


/*****************skeltor loader css */

.vue-skeletor {
    position: relative;
    overflow: hidden;
    background-color: rgba(0, 0, 0, 0.12);
}

.vue-skeletor:not(.vue-skeletor--shimmerless):after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transform: translateX(-100%);
    background-image: linear-gradient(90deg,
            rgba(255, 255, 255, 0),
            rgba(255, 255, 255, 0.3),
            rgba(37, 22, 22, 0));
    animation: shimmer 1.5s infinite;
    content: "";
}

.vue-skeletor--rect,
.vue-skeletor--circle {
    display: block;
}

.vue-skeletor--circle {
    border-radius: 50%;
}

.vue-skeletor--pill,
.vue-skeletor--text {
    border-radius: 9999px;
}

.vue-skeletor--text {
    line-height: 1;
    display: inline-block;
    width: 100%;
    height: inherit;
    vertical-align: middle;
    top: -1px;
}

@keyframes shimmer {
    100% {
        transform: translateX(100%);
    }
}

/************** ScrollBar Css **************/

.order-pepth ::-webkit-scrollbar ,
.list_box_crypto ::-webkit-scrollbar ,
.staking_history ::-webkit-scrollbar {
    background: transparent;
    width: 3px;
    height: 0;
    border-radius: 10px;
}

.order-pepth ::-webkit-scrollbar-thumb ,
.list_box_crypto ::-webkit-scrollbar-thumb ,
.staking_history ::-webkit-scrollbar-thumb {
    background-color: var(--scroll-fill);
    border: solid rgba(0, 0, 0, 0) 2px;
    transition: all 0.4s ease;
}

/*****************skeltor loader css *******/

/*****************skeltor loader css */

.vue-skeletor {
  position: relative;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.12);
}

.vue-skeletor:not(.vue-skeletor--shimmerless):after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transform: translateX(-100%);
  background-image: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 0.3),
    rgba(37, 22, 22, 0)
  );
  animation: shimmer 1.5s infinite;
  content: "";
}

.vue-skeletor--rect,
.vue-skeletor--circle {
  display: block;
}

.vue-skeletor--circle {
  border-radius: 50%;
}

.vue-skeletor--pill,
.vue-skeletor--text {
  border-radius: 9999px;
}

.vue-skeletor--text {
  line-height: 1;
  display: inline-block;
  width: 100%;
  height: inherit;
  vertical-align: middle;
  top: -1px;
}

@keyframes shimmer {
  100% {
    transform: translateX(100%);
  }
}

/*****************skeltor loader css *******/


.pageination_div .VuePagination nav p {
    text-align: right;
    color:var(--px-white);
}
// slider lounchpad
.slidder .swiper-button-next:after, .swiper-button-prev:after{
    display: none !important; 
}

/* ================================================
                        MEDIA QUERY
================================================ */

@media all and (min-width: 992px) and (max-width: 1199px) {
    .auth_page .auth_box {
        padding: 20px !important;
    }
}

@media all and (min-width: 768px) and (max-width: 991px) {
    .auth_page .auth_box {
        padding: 20px !important;
    }
}

@media (min-width:320px) and (max-width:767px) {
    .auth_page .auth_box {
        padding: 15px !important;
    }

    .auth_page .main_headings h2,
    .auth_page .main_headings h2 span {
        font-size: 30px;
    }

    input.otp-input {
        height: 40px !important;
        width: 38px !important;
        margin-right: 0;
    }

    .btn_next,
    .btn_back {
        width: 110px;
    }



    
}
</style>

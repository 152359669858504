<template>
  <div class="home">
    <!-- ******** HOME BANNER SECTION ******** -->
    <section class="home-banner d-flex justify-content-center align-items-center p-0">
      <div class="container">
        <div class="row justify-content-between mb-5">
          <div class="col-md-12 col-lg-6 col-xl-5 align-self-center">
            <div>
              <h1 class="mb-4 mt-4 md-mt-0">Kick start your crypto portfolio</h1>
              <p>Buy, Sell & Trade Crypto Instantly. Register now to get started.</p>
            </div>
            <form @submit.prevent="formSumbit()">
              <div class="register_now">
                <div class="input-group d-flex mb-1 rounded-pill">
                  <input
                    type="email"
                    class="form-control rounded-pill"
                    v-model="v$.email.$model"
                    placeholder="Email address"
                    aria-label="email"
                    aria-describedby="basic-addon1"
                  />

                  <button
                    type="submit"
                    class="input-group-text ms-3 rounded-pill"
                    id="basic-addon1"
                  >Register now</button>
                </div>
                <span class="text-danger" v-if="v$.email.$error">
                  <div class="ps-3">{{ v$.email.$errors[0].$message }}</div>
                </span>
              </div>
            </form>
          </div>
          <div class="col-md-12 col-lg-6 col-xl-6">
            <div class="banner-left text-center position-relative">
              <img src="../assets/images/banner-img.png" alt="image" class="img-fluid" />
            </div>
          </div>
        </div>
        <!----calulator loader ----->
        <div class="row justify-content-center" v-if="cal_loader">
          <div class="col-md-12 col-lg-4 col-xl-5 mb-3 rounded">
            <Skeletor height="45px" pill />
          </div>
          <div class="col-md-12 col-lg-4 col-xl-5 mb-3 rounded">
            <Skeletor height="45px" pill />
          </div>
          <div class="col-md-12 col-lg-4 col-xl-2 mb-3 text-center">
            <Skeletor height="45px" pill />
          </div>
          <div class="col-5 text-center mt-3">
            <Skeletor pill />
          </div>
        </div>
        <div class="row give_receive align-items-center" v-else>
          <div class="col-md-12 col-lg-4 col-xl-5">
            <div class="input-group mb-3 rounded-pill p-1 align-items-center">
              <span class="ms-3 p-2">Give</span>
              <input
                type="text"
                class="form-control border-0"
                v-model="given_value"
                aria-label="email"
                aria-describedby="basic-addon1"
                onkeypress="return (event.charCode !=8 && ((event.charCode >= 48 && event.charCode <= 57) || (event.charCode == 46 && this.value.indexOf('.') == -1)))"
                @keyup="priceCal()"
              />
              <span class="input-group-text ms-3 rounded-pill" id="basic-addon1">
                <div class="currency_box">
                  <div class="select-box">
                    <div class="dropdown">
                      <button
                        class="btn-drop text-uppercase dropdown-toggle d-flex align-items-center"
                        type="button"
                        id="dropdownMenuButton1"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        <img
                          :src=" require(`@/assets/pairs/${selected_pimage}`)"
                          alt
                          class="img-fluid me-2"
                          style="height:30px"
                        />
                        <span>{{selected_pair}}</span>
                      </button>
                      <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                        <li
                          class="list_items text-uppercase"
                          v-for="(pair, index) in pair_with"
                          :key="index"
                          @click="pairSelect(pair)"
                        >
                          <img
                            :src="pairImage(pair)"
                            alt
                            class="img-fluid me-2"
                            style="height:30px"
                          />
                          <span>{{ pair }}</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </span>
            </div>
          </div>

          <div class="col-md-12 col-lg-4 col-xl-5">
            <div class="input-group mb-3 rounded-pill p-1 align-items-center">
              <span class="ms-3 p-2">Receive</span>
              <input
                type="text"
                class="form-control border-0"
                v-model="receive_value"
                aria-label="email"
                aria-describedby="basic-addon1"
                onkeypress="return (event.charCode !=8 && ((event.charCode >= 48 && event.charCode <= 57) || (event.charCode == 46 && this.value.indexOf('.') == -1)))"
                @keyup="currency_handler()"
              />
              <span class="input-group-text ms-3 rounded-pill" id="basic-addon1">
                <div class="currency_box">
                  <div class="select-box">
                    <div class="currency_drop dropdown">
                      <button
                        class="btn-drop text-uppercase dropdown-toggle d-flex align-items-center"
                        type="button"
                        id="dropdownMenuButton2"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        <img :src="selected_cimage" alt class="img-fluid me-2" style="height:30px" />
                        <span>{{ selected_currency }}</span>
                      </button>
                      <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton2">
                        <li
                          class="list_items text-uppercase"
                          v-for="(data, index) in currencies"
                          :key="index"
                          @click="currencySelect(data)"
                        >
                          <img :src="data.image" alt class="img-fluid me-2" style="height:30px" />
                          <span>{{ data.currency }}</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </span>
            </div>
          </div>

          <div class="col-md-12 col-lg-4 col-xl-2 text-center">
            <button type="button" @click="btn_exch()" class="px_btn mb-3">Exchange</button>
          </div>

          <div class="col-12 text-center">
            <p>
              1 {{selected_pair}} = {{parseFloat(sel_currency_price)}} {{selected_currency}}
              <span
                class="px-2"
              >|</span> Fee : 0.1%
            </p>
          </div>
        </div>
      </div>
    </section>

    <!-- ******** PAISA XC BANNER SECTION ******** -->
    <section class="poster-slider">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <!-- <div class="swiper-section mb-5"> 
                            <div class="d-flex justify-content-between w-100"   >                         
                                <div style="flex-basis:26.3%" v-for="i in 3" :key="i"> <Skeletor  height="200px" /> </div>                                   
                            </div>                       
            </div>-->
            <!-- <div class="swiper-section mb-5">
                            <swiper :autoplay="{ delay: 2500, disableOnInteraction: false }" :navigation="true"
                                :modules="modules" class="mySwiper" :slides-per-view="3" :loop="true"
                                :space-between="30" :spaceBetween="10" :pagination="{ clickable: true }" :breakpoints="{
                                        '320': {
                                            slidesPerView: 1,
                                            spaceBetween: 30,
                                        },
                                        '767': {
                                            slidesPerView: 2,
                                            spaceBetween: 30,
                                        },
                                        '1024': {
                                            slidesPerView: 3,
                                            spaceBetween: 120,
                                        },
                                        }">
                                    <swiper-slide  v-for="(data,i) in banners" :key="i">
                                         <div v-if="banner_load"><Skeletor  height="200px" /></div>
                                        <img v-else :src="data.image" alt="img" class="img-fluid d-block" />
                                    </swiper-slide>
                            </swiper>
            </div>-->
          </div>
          <!------PAISA XC Market SECTION------>
          <MarketComponent :crypto="allCrypto" :s_data="allData" />
        </div>
      </div>
    </section>

    <!-- INTRODUCING PAISA XC -->
    <!-- <section class="intro_px">
            <div class="container">
                <MainHeading main_heading="INTRODUCING  PAISAXC"> </MainHeading>
                <div class="row justify-content-center">
                    <div class="col-xl-5 align-self-center">
                        <h3 class="mb-3">About PaisaXC</h3>
                        <p>
                            PaisaXC, a utility token backed by PaisaXC, forms the backbone of
                            PaisaXC ecosystem. We launched PaisaXC tokens to involve our
                            community in helping us build out PaisaXC, and reward them
                            accordingly for contributing to our success. This helps us stay
                            true to the ethos of cryptocurrency and blockchain - to share the
                            rewards of PaisaXC's success with our early adopters and
                            supporters.
                        </p>
                    </div>
                    <div class="col-xl-6 text-center">
                        <img src="../assets/images/px_intro.png" alt=" images" class="img-fluid" />
                    </div>
                </div>
            </div>
    </section>-->
    <!-- Get going in 3 easy steps -->
    <section class="get_started">
      <div class="container">
        <MainHeading main_heading="Get going in 3 easy steps"></MainHeading>
        <div class="row justify-content-lg-between align-items-center justify-content-center">
          <div class="col-xl-3 col-lg-4 col-md-6" v-for="(data, index) in GetData" :key="index">
            <div class="d-flex align-items-center mb-4 mb-lg-3">
              <div class="px-2 p-1">
                <img :src="require(`@/assets/images/${data.image}`)" class="mb-3 img-fluid"/>
                <h3 class="mb-3">{{ data.heading }}</h3>
                <p>{{ data.para }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- Features of PaisaXC -->
    <section class="px_features">
      <div class="container">
        <MainHeading main_heading="Leading-edge crypto security"></MainHeading>

        <div class="row justify-content-between">
          <div
            class="col-xl-3 col-md-4 col-lg-4 text-center"
            v-for="(data, index) in FeatureData"
            :key="index"
          >
            <div class="d-flex align-items-center">
              <div>
                <img
                  :src="require(`@/assets/images/${data.image}`)"
                  alt="image"
                  class="mb-3 img-fluid"
                />
                <h3 class="mb-3">
                  {{ data.heading }}
                  <br />
                  {{ data.heading1 }}
                </h3>
                <p class="mb-3 p-2">{{ data.para }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- Your trusted Cryptoexchange -->

    <!-- <section class="trusted_cryptoexchange">
            <div class="container">
                <MainHeading main_heading="YOUR TRUSTED CRYPTOEXCHANGE"> </MainHeading>

                <div class="row flex-column-reverse flex-xl-row">
                    <div class="steps_heading col-xl-6 col-lg-12 col-md-12 align-self-center">
                        <h4 class="mb-4">
                            Here at PaisaXC, we are committed to user protection with strict
                            protocols and industry-leading technical measures.
                        </h4>
                        <div class="steps_main_box">
                            <div class="p-2 about_steps">
                                <h3><span>01.</span> Secure Asset Fund for Users (SAFU)</h3>
                                <p>
                                    Binance stores 10% of all trading fees in a secure asset fund
                                    to protect a share of user funds.
                                </p>
                            </div>
                        </div>
                        <div class="steps_main_box">
                            <div class="p-2 about_steps">
                                <h3><span>02.</span> Personalised Access Control</h3>
                                <p>
                                    Advanced access control allows you to restrict devices and
                                    addresses that can access your account, for greater ease of
                                    mind.
                                </p>
                            </div>
                        </div>
                        <div class="steps_main_box">
                            <div class="p-2 about_steps">
                                <h3><span>03.</span> Advanced Data Encryption</h3>
                                <p>
                                    Your transaction data is secured via end-to-end encryption,
                                    ensuring that only you have access to your personal
                                    information.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-6 col-lg-12 col-md-12">
                        <div class="text-center mb-3">
                            <img src="../assets/images/Cryptoexchange.png" alt="mobile img" class="img-fluid mb-3" />
                        </div>
                    </div>
                </div>
            </div>
    </section>-->

    <!-- Why Choose US? -->
    <section class="choose_us">
      <div class="container">
        <MainHeading main_heading="WHY CHOOSE US ?"></MainHeading>

        <div class="row justify-content-between">
          <div
            class="col-xl-3 col-lg-4 text-center"
            v-for="(data, index) in ChooseUsData"
            :key="index"
          >
            <div class="d-flex align-items-center justify-content-center">
              <div class="need_box">
                <img
                  :src="require(`@/assets/images/${data.image}`)"
                  alt="image"
                  class="mb-3 img-fluid"
                />
                <h3 class="mb-3">{{ data.heading }}</h3>
                <p class="mb-3">{{ data.para }}</p>
                <router-link v-if="data.action" to="action" class="text-capitalize">{{data.action}}</router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- Need Help ? -->
    <section class="need_help">
      <div class="container">
        <MainHeading main_heading="Need Help ?"></MainHeading>

        <div class="row justify-content-between mb-5">
          <div
            class="col-xl-3 col-lg-3 col-md-12 text-center"
            v-for="(data, index) in NeedHelpData"
            :key="index"
          >
            <div class="d-flex align-items-center justify-content-center">
              <div class="need_box">
                <img :src="require(`@/assets/images/${data.image}`)" alt="image" class="mb-3" />
                <h3 class="mb-3">{{ data.heading }}</h3>
                <p class="mb-3">{{ data.para }}</p>
                <router-link v-if="data.action" to="action" class="text-capitalize">{{data.action}}</router-link>
              </div>
            </div>
          </div>
        </div>

        <!-- Trusted By 1+ Million Indians. Create your own portfolio.  -->
        <div class="create_port_box m-md-3 m-0 p-0 p-md-4">
          <div class="row justify-content-center create_portfolio p-4">
            <div class="col-xl-6">
              <div class="text-center">
                <img src="../assets/images/need-mob.png" alt="image" class="img-fluid mb-2 mb-lg-0" />
              </div>
            </div>
            <div class="col-xl-6 align-self-center">
              <div class="text-center">
                <h3>
                  Install PaisaXc, Today's leading cryptocurrency investment app.
                  Mobile trading. Anytime, anywhere.
                </h3>
              </div>
              <div class="social_icons_port mt-5">
                <div class="d-flex justify-content-center mb-5">
                  <div class="px-3">
                    <img src="../assets/images/n-1.png" alt="image" class="img-fluid" />
                  </div>
                  <div class="px-3">
                    <img src="../assets/images/n-2.png" alt="image" class="img-fluid" />
                  </div>
                </div>
                <div class="d-flex justify-content-center">
                  <div class="px-3">
                    <img src="../assets/images/n-3.png" alt="image" class="img-fluid" />
                  </div>
                  <div class="px-3">
                    <img src="../assets/images/n-4.png" alt="image" class="img-fluid" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- FAQ SECTION -->

    <section class="faq_section">
      <div class="container">
        <MainHeading main_heading="FAQ"></MainHeading>
        <div class="row">
          <div class="col-md-12 col-lg-12 col-xl-12">
            <div class="faq_tabs_box">
              <ul class="nav nav-pills flex-nowrap text-nowrap mb-5" id="pills-tab" role="tablist">
                <li class="nav-item" role="presentation" v-for="(data,index) in category" :key="index">
                <!-- <li class="nav-item" role="presentation"> -->
                  <button
                    class="nav-link "
                    :class="(activeIndex == index) ? 'active' : ''"
                    id="pills-Faq-Tab1-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-Faq-Tab1"
                    type="button"
                    @click="activeIndex = index,accData = faq[data]"
                    role="tab"
                    aria-controls="pills-Faq-Tab1"
                    aria-selected="true"
                  >{{data}} </button>
                </li>
             
              </ul>
              <div class="tab-content" id="pills-tabContent">
                <div
                  class="tab-pane fade show active"
                  id="pills-Faq-Tab1"
                  role="tabpanel"
                  aria-labelledby="pills-Faq-Tab1-tab"
                  tabindex="0"
                >
                  <div class="accordion accordion-flush" :id="'faq'+index" v-for="(data,index) in accData" :key="index">
                    <div class="accordion-item">
                      <h2 class="accordion-header" id="flush-headingOne">
                        <button
                          class="accordion-button shadow-none collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          :data-bs-target="'#collapswFaq'+index"
                          aria-expanded="false"
                          aria-controls="collapswFaq"
                        > {{data.question}}</button>
                      </h2>
                      <div
                        :id="'collapswFaq'+index"
                        class="accordion-collapse collapse"
                        aria-labelledby="flush-headingOne"
                        :data-bs-parent="'#faq'+index"
                      >
                        <div
                          class="accordion-body"
                        >  {{data.answer}}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- FAQ SECTION END -->
  </div>
</template>

<script>
const config = {
  returnString: true,
  eMinus: Infinity,
  ePlus: Infinity,
  maxDecimal: 8
};
// MAIN HEADING COMPONENT
import MainHeading from "@/components/LandingPage/MainHeading.vue";
import MarketComponent from "@/components/LandingPage/MarketComponent.vue";
import landingdata from "@/assets/json/landingdata";

// import {Swiper,SwiperSlide} from "swiper/vue";
// import "swiper/css";
// import "swiper/css/autoplay";
// import "swiper/css/pagination";
// import {Autoplay,Navigation,Pagination} from "swiper";
import { required, helpers, email } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
import ApiClass from "@/api/api";

import exactMath from "exact-math";

export default {
  name: "HomeView",
  components: {
    MainHeading,
    // Swiper,
    // SwiperSlide,
    MarketComponent
  },
  setup() {
    return {
      // modules: [Autoplay, Navigation, Pagination],
      v$: useVuelidate()
    };
  },
  data() {
    return {
      banners: [],
      faq: [],
      category: [],
      activeIndex:0,
      accData:[],

      banner_load: true,

      allCrypto: {},
      allData: {},

      //calculator start
      cal_loader: true,
      email: "",
      pair_with: [],
      selected_pair: "USDT",
      selected_pimage: "tether.png",
      dataBtc: [],
      dataUsdt: [],
      dataBnb: [],
      dataEth: [],
      currencies: [],
      selected_currency: "",
      selected_cimage: "",
      given_value: "1",
      receive_value: 0,
      sel_currency_price: 0,
      currency_price: 0,
      //calculator end

      GetData: landingdata.GetData,
      FeatureData: landingdata.FeatureData,
      ChooseUsData: landingdata.ChooseUsData,
      NeedHelpData: landingdata.NeedHelpData
    };
  },

  async mounted() {
    await this.getbanners();
    await this.getCrypto();
    await this.getFAQ();
    await this.pairSelect("USDT");
  },
  validations() {
    return {
      email: {
        required: helpers.withMessage("Email is required.", required),
        email: helpers.withMessage("Invalid Email Address", email)
      }
    };
  },
  methods: {
    btn_exch() {
      this.$router.push({
        path: "/exchange",
        query: { s: this.selected_currency + this.selected_pair }
      });
    },
    async formSumbit() {
      let result = await this.v$.$validate();
      if (!result) {
        return;
      }
      localStorage.setItem("home_email", this.email);
      this.$router.push({ name: "RegisterView" });
    },
    async getbanners() {
      let res = await ApiClass.getRequest("banner/get", false);
      if (res?.data.status_code == 1) {
        this.banner_load = false;
        this.banners = res.data.data;
      }
    },
    //calculator start
    async getCrypto() {
      // let result = await ApiClass.getNodeRequest("list-crypto/get", false);

      let result = await ApiClass.getNodeRequest(
        "list-crypto/get_markets",
        false
      );

      if (result.data.status_code == 1) {
        this.cal_loader = false;
        this.allCrypto = result.data; //allCrypto send data to child marketcomponent

        this.allData = result.data.data; //search dropdown for child marketcomponent

        this.allData = this.allData.USDT.concat(
          this.allData.BTC,
          this.allData.BNB,
          this.allData.ETH
        );
        // console.log('allData==',this.allData)

        let pairs = result.data.data;
        this.pair_with = Object.keys(pairs);

        this.dataUsdt = result.data.data.USDT;
        this.dataBtc = result.data.data.BTC;
        this.dataBnb = result.data.data.BNB;
        this.dataEth = result.data.data.ETH;

        //initially selected currencies
        this.selected_currency = this.dataUsdt[0].currency;
        this.selected_cimage = this.dataUsdt[0].image;

        //price calculation
        this.currency_price = parseFloat(this.dataUsdt[0].price);
        this.priceCal();
      }
    },
    async getFAQ() {
      // let result = await ApiClass.getNodeRequest("list-crypto/get", false);

      let result = await ApiClass.getNodeRequest("faq/getfaq", false);

      if (result.data.status_code == 1) {
        this.faq = result.data?.data.faqs;
        this.category = result.data?.data.categories;

        this.accData = this.faq[this.category[0]];

        console.log({faq: this.accData},result.data?.data.faqs);

      }
    },

    pairImage(pair) {
      if (pair == "USDT") {
        return require("@/assets/pairs/tether.png");
      }
      if (pair == "BTC") {
        return require("@/assets/pairs/btc.png");
      }
      if (pair == "BNB") {
        return require("@/assets/pairs/bnb.png");
      }
      if (pair == "ETH") {
        return require("@/assets/pairs/eth.png");
      }
    },

    currency_handler() {
      var rv = this.receive_value == "" ? "0" : this.receive_value;
      if (rv.endsWith(".") && rv != "") {
        rv = rv.slice(0, rv.length - 1);
      }
      this.given_value = exactMath.div(rv, this.sel_currency_price, config);
    },

    priceCal() {
      this.sel_currency_price = exactMath.div(1, this.currency_price, config);
      var gv = this.given_value == "" ? "0" : this.given_value;
      if (gv.endsWith(".") && gv != "") {
        gv = gv.slice(0, gv.length - 1);
      }
      this.receive_value = parseFloat(
        exactMath.mul(gv, this.sel_currency_price, config)
      );
    },

    pairSelect(pair) {
      // console.log('pair', pair)
      if (pair == "USDT") {
        this.selected_pair = pair;
        this.selected_pimage = "tether.png";
        this.currencies = this.dataUsdt;

        //initially selected currencies
        this.selected_currency = this.dataUsdt[0].currency;
        this.selected_cimage = this.dataUsdt[0].image;

        //price calculation
        this.currency_price = parseFloat(this.dataUsdt[0].price);
        this.priceCal();
      }
      if (pair == "BTC") {
        this.selected_pair = pair;
        this.selected_pimage = "btc.png";
        this.currencies = this.dataBtc;

        //initially selected currencies
        this.selected_currency = this.dataBtc[0].currency;
        this.selected_cimage = this.dataBtc[0].image;

        //price calculation
        this.currency_price = parseFloat(this.dataBtc[0].price);
        this.priceCal();
      }
      if (pair == "BNB") {
        this.selected_pair = pair;
        this.selected_pimage = "bnb.png";
        this.currencies = this.dataBnb;

        //initially selected currencies
        this.selected_currency = this.dataBnb[0].currency;
        this.selected_cimage = this.dataBnb[0].image;

        //price calculation
        this.currency_price = parseFloat(this.dataBnb[0].price);
        this.priceCal();
      }
      if (pair == "ETH") {
        this.selected_pair = pair;
        this.selected_pimage = "eth.png";
        this.currencies = this.dataEth;

        //initially selected currencies
        this.selected_currency = this.dataEth[0].currency;
        this.selected_cimage = this.dataEth[0].image;

        //price calculation
        this.currency_price = parseFloat(this.dataEth[0].price);
        this.priceCal();
      }
    },
    currencySelect(data) {
      //on ckick selected currencies
      this.selected_currency = data.currency;
      this.selected_cimage = data.image;

      //price calculation
      this.currency_price = parseFloat(data.price);
      this.priceCal();
    }
  }
};
</script>

<style scoped>
/* faq-section start */
.faq_section .nav-pills .nav-link {
  color: var(--px-black);
  font-size: 15px;
  font-weight: 600;
}

.faq_section .nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: var(--px-pink);
  text-decoration: 2px underline;
  background-color: unset;
  text-underline-offset: 8px;
}
.faq_section .accordion {
  border: 1px solid var(--border);
  border-radius: 5px;
}
.faq_section .accordion .accordion-item {
  background-color: unset;
}
.faq_section .accordion-button {
  font-size: 16px;
  font-weight: 500;
  color: var(--black);
}
.faq_section .accordion-body {
  font-size: 15px;
  font-weight: 500;
  color: var(--black);
}
.faq_section .accordion-button:not(.collapsed) {
  background-color: var(--px-pink);
  color: var(--white);
  font-weight: 500;
}
.faq_section .accordion-button:not(.collapsed)::after {
  background-image: url("@/assets/images/icons/faq1.svg");
  background-repeat: no-repeat;
}

/* faq section end */
.top-gain {
  align-items: center;
  text-align: center;
  display: flex;
  justify-content: center;
}

.btn-drop {
  /* color: #212529; */
  vertical-align: middle;
  cursor: pointer;
  background-color: transparent;
  border: 1px solid transparent;
  font-size: 13px;
}

.list_items {
  color: var(--black-land);
  font-size: 13px;
  padding: 5px 8px;
  cursor: pointer;
}

.list_items:hover {
  background-color: var(--light-grey);
}

ul.dropdown-menu.show {
  transform: translate(0px, 43px) !important;
}

.dropdown-toggle::after {
  background-image: url(../assets/images/icons/drop_down.svg);
  display: inline-block;
  margin-left: 0.255em;
  background-repeat: no-repeat;
  content: "";
  border-top: 0 solid;
  border-right: 0 solid transparent;
  border-bottom: 0;
  border-left: 0 solid transparent;
  height: 13px;
  width: 15px;
}
.currency_drop ul {
  height: 250px;
  overflow-y: scroll;
}

section {
  padding: 70px 0;
}

.banner-left {
  position: relative;
  min-height: 600px;
}

.banner-left img {
  position: relative;
}

.banner-left::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  background-image: url(../assets/images/round-img.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 576px;
  height: 576px;

  -webkit-animation-name: spin;
  -webkit-animation-duration: 40000ms;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  -moz-animation-name: spin;
  -moz-animation-duration: 40000ms;
  -moz-animation-iteration-count: infinite;
  -moz-animation-timing-function: linear;
  -ms-animation-name: spin;
  -ms-animation-duration: 40000ms;
  -ms-animation-iteration-count: infinite;
  -ms-animation-timing-function: linear;

  animation-name: spin;
  animation-duration: 40000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@-moz-keyframes spin {
  from {
    -moz-transform: rotate(0deg);
  }

  to {
    -moz-transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.home-banner {
  background: url(../assets/images/banner-bg.png) var(--off-white);
  min-height: 95vh;
}

.home-banner p {
  color: var(--px-grey);
  font-weight: 500;
  font-family: "Poppins", sans-serif;
}

.home-banner h1 {
  font-size: 45px;
  font-weight: 500;
  letter-spacing: 2px;
}

/* FORM CONTROL  */
.register_now .form-control {
  border: 1px solid var(--px-pink);
  background: transparent;
  padding: 10px 30px;
}

.register_now .form-control:focus {
  box-shadow: none;
}

.register_now .input-group-text {
  padding: 6px 20px;
  font-size: 14px;
  color: var(--white);
  background: linear-gradient(
    90deg,
    rgba(253, 1, 148, 1) 3%,
    rgba(235, 13, 123, 1) 62%,
    rgba(153, 0, 177, 0.8939950980392157) 79%
  );
  padding: 10px 30px;
}

.give_receive .input-group-text {
  font-size: 14px;
  color: var(--px-black);
  background-color: var(--banner-grey);
  border: 1px solid var(--border);
  padding: 5px 3px;
}

.give_receive .input-group {
  border: 1px solid var(--border);
  font-family: "Poppins", sans-serif;
}

.give_receive .form-control {
  background-color: transparent;
}

.form-select {
  padding: 0px 20px;
  background-color: transparent !important;
  background-position: right center;
  border: 0px solid;
}

.form-select:focus,
.form-control:focus {
  box-shadow: none;
}

.px_btn {
  color: var(--white);
  cursor: pointer;
  background: linear-gradient(
    90deg,
    rgba(253, 1, 148, 1) 3%,
    rgba(235, 13, 123, 1) 62%,
    rgba(153, 0, 177, 0.8939950980392157) 79%
  );
  border: 1px solid transparent;
  font-size: 14px;
  border-radius: 20px;
  transition: all 0.2s ease-in-out;
  padding: 10px 30px;
}

/* Poster Section css */
/* .market_status .input-group-text {
    background-color: transparent;
}

.market_status .input-group {
    border: 1px solid var(--border);
}

.market_status h3 span {
    color: var(--px-red);
    font-family: "Poppins", sans-serif;
}

.market_status h3 {
    font-size: 25px;
}

.market_status p span {
    font-weight: 400;
    font-family: "Poppins", sans-serif;
    color: var(--span);
}

.m_heading h4 {
    font-size: 16px;
    font-weight: 500;
}

.market_status p {
    color: var(--light-text);
    font-size: 14px;
}

.top_gainer p {
    color: var(--light-text);
    font-size: 15px;
    font-family: "Poppins", sans-serif;
}

.top_gainer {
    position: relative;
}

.top_gainer h3 {
    font-size: 18px;
}

.top_gainer h3 span {
    color: var(--px-purple);
    font-family: "Poppins", sans-serif;
    font-size: 17px;
} */

/* .market_image img {
} */
/* .market_image {
    position: relative;
}

.market_image::before {
    position: absolute;
    content: "";
    background-image: url(../assets/images/border-img.png);
    width: 285px;
    height: 130px;
    top: 7px;
    left: 29px;
    right: 0;
    bottom: 0;
    margin: auto;
    background-repeat: no-repeat;
} */

/* background-color: var(--approach-box);

border-bottom: 3px solid var(--setting-border);
border-right: 3px solid var(--setting-border);
border-top: 25px solid transparent;
border-left: 25px solid transparent;
transform: rotate(45deg); */

/* INTRODUCING PAISA XC */
.intro_px {
  background-color: var(--dark-purple);
}

.intro_px h3 {
  color: var(--white);
  letter-spacing: 1px;
}

.intro_px p {
  color: var(--intro-grey);
  letter-spacing: 1px;
}

/* get_started */
.get_started p,
.px_features p {
  color: var(--text-grey);
  font-size: 13px;
  letter-spacing: 1px;
  font-weight: 500;
}

.get_started h3,
.px_features h3 {
  font-size: 19px;
  font-weight: 600;
  /* white-space: nowrap; */
  letter-spacing: 1px;
}

/* -- Features of PaisaXC  -- */
.px_features {
  background-color: var(--off-white);
}

.about_steps h3 span {
  color: var(--intro-grey);
  font-size: 40px;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
}

.about_steps h3 {
  font-size: 19px;
  color: var(--light-pink);
  font-weight: 500;
}

.about_steps p {
  color: var(--trust_grey);
  font-weight: 500;
  font-size: 15px;
}

.steps_heading h4 {
  font-size: 19px;
  letter-spacing: 1px;
}

/* choose_us */
.choose_us {
  background-color: var(--dark-purple);
}

.choose_us h3 {
  font-size: 20px;
  color: var(--white);
  font-weight: 500;
  line-height: 27px;
}

.choose_us p {
  font-size: 13px;
  letter-spacing: 1px;
  color: var(--intro-grey);
  line-height: 25px;
}

/* need_help */
.need_help h3 {
  font-size: 18px;
  color: var(--px-pink);
  font-weight: 400;
  font-family: "Poppins", sans-serif;
}

.need_help p {
  font-size: 13px;
  letter-spacing: 1px;
  color: var(--text-grey);
}

/* Trusted By 1+ Million Indians. Create your own portfolio.  */
.create_portfolio {
  background-color: var(--dark-purple);
}

.create_portfolio h3 {
  color: var(--white);
  font-size: 30px;
  letter-spacing: 1px;
}

.create_port_box {
  position: relative;
}

.create_port_box::before {
  content: "";
  position: absolute;
  height: 85px;
  width: 85px;
  background-repeat: no-repeat;
  background-image: url(../assets/images/r-border.png);
  z-index: -1;
  top: -22px;
  left: -26px;
}

.create_port_box::after {
  content: "";
  position: absolute;
  background-image: url(../assets/images/l-border.png);
  z-index: -1;
  height: 90px;
  width: 89px;
  background-repeat: no-repeat;
  bottom: -21px;
  right: -39px;
}
/* start css */
.need_box a {
  color: var(--px-pink);
  font-size: 15px;
  font-weight: 600;
  text-decoration: 2px solid underline;
  text-underline-offset: 4px;
}

@media (min-width: 1200px) and (max-width: 1399px) {
  .market_image::before {
    background-image: url(../assets/images/smallborder-img.png);
    top: 20px;
    left: 32px;
  }
  .m_heading h4 {
    text-align: start;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .banner-left::before {
    display: none;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .top-gain {
    justify-content: center;
  }
}

@media (min-width: 320px) and (max-width: 767px) {
  .home-banner h1 {
    font-size: 36px;
  }

  .section-heading::before {
    width: 304px;
    background-repeat: no-repeat;
    background-size: contain;
  }

  .create_port_box::before {
    display: none;
  }

  .create_port_box::after {
    display: none;
  }

  .create_portfolio h3 {
    font-size: 20px;
  }

  .banner-left::before {
    display: none;
  }

  .status_box {
    justify-content: start;
  }

  .market_image::before {
    width: 251px;
    background-image: url(../assets/images/smallborder-img.png);
    top: 18px;
    left: 31px;
  }
  .faq_section .nav-pills {
    overflow-x: scroll;
  }
}
</style>

import { createStore } from 'vuex'
import createPersistedState from 'vuex-persistedstate';

export default createStore({
  plugins: [createPersistedState()],
  state: {
    maintenance: null,
    payment_name: null,
    show_upi: null,
    lachData: null,
    user: null,
    isLogin: false,
    theme: 1,
    wd_data: '',
    dp_data: '',
    wv_data: '',
    favourite: [],
    gauth_setup: false,
    gauth_status: false,
    gauth_secret: null,
    ticke_data: [],
    otp: '',
    ask: '',
    editOrderInfo: null,
    ptype: 'usdt',
    allCurrency: '',
    prices: '',
    stake: '',
    stakeRoute: 0,
    matchData: {}
  },
  getters: {
    getMaintenance: (state) => {
      return state.maintenance;
    },
    getmatchData(state) {
      return state.matchData
    },
    getTheme(state) {
      return state.theme
    },
    getWd_data(state) {
      return state.wd_data
    },
    getDP_data(state) {
      return state.dp_data
    },
    getWV_data(state) {
      return state.wv_data
    },
    favouriteCryptos: state => {
      return state.favourite;
    },
    getIsLogin: state => {
      return state.isLogin;
    },
    getUser: state => {
      return state.user;
    },

    getGoogleAuth: state => {
      return state.gauth_status;
    },
    getGauth: state => {
      return state.gauth_setup;
    },
    get_secret: state => {
      return state.gauth_secret;
    },
    getTickets(state) {
      return state.ticke_data
    },

    getOtpData: state => {
      return state.otp;
    },
    getAsk: state => {
      return state.ask;
    },
    getEditInfo: state => {
      return state.editOrderInfo;
    },
    getPriceType: state => {
      return state.ptype;
    },
    getAllCurrency: state => {
      return state.allCurrency;
    },
    getPrices: state => {
      return state.prices;
    },
    getStakeData: state => {
      return state.stake;
    },
    getLunData(state) {
      return state.lachData;
    },
    getsatkeHis(state) {
      return state.stakeRoute;
    },
    getpayment_name(state) {
      return state.payment_name;
    },
    getshow_upi(state) {
      return state.show_upi;
    },

  },
  mutations: {
    initialMaintenance(state, val) {
      state.maintenance = val;
    },
    setmatchData(state, payload) {
      state.matchData = payload
    },
    setpayment_name(state, payload) {
      state.payment_name = payload
    },
    setshow_upi(state, payload) {
      state.show_upi = payload
    },
    lcnPdData(state, payload) {
      state.lachData = payload;
    },
    setTheme(state, payload) {
      state.theme = payload
    },
    SET_FAVOURITE(state, val) {
      state.favourite = val;
    },

    SET_WD(state, payload) {
      state.wd_data = payload
    },

    SET_DP(state, payload) {
      state.dp_data = payload
    },

    SET_W_VIEW(state, payload) {
      state.wv_data = payload
    },
    setLogin(state, value) {
      state.isLogin = value;
    },
    SET_USER(state, user) {
      state.user = user;
    },

    SET_GAUTH(state, payload) {
      state.gauth_setup = payload
    },
    SET_GOOGLE_STATUS(state, payload) {
      state.gauth_status = payload;
    },
    SET_SECRET(state, payload) {
      state.gauth_secret = payload;
    },
    TiketList(state, payload) {
      state.ticke_data = payload
    },

    setOtpData(state, data) {
      state.otp = data;
    },
    neverAsk(state, data) {
      state.ask = data;
    },
    setEditOrderInfo(state, payload) {
      state.editOrderInfo = payload;
    },
    setPriceType(state, payload) {
      state.ptype = payload;
    },
    SET_Market_Currency(state, payload) {
      state.allCurrency = payload;
    },
    conversionPrices(state, payload) {
      state.prices = payload;
    },
    setStakeData(state, payload) {
      state.stake = payload;
    },
    stakeHistory(state, payload) {
      state.stakeRoute = payload;
    },

  },

})

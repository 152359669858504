<template>
<div class="row mb-5">
    <div class="col-md-12">
        <!-- MAIN-HEADING -->
        <div class="section-heading position-relative text-center">
            <h2 >{{main_heading}}</h2>
        </div>
        <!-- <div class="heading-bottom text-center">
            <img src="../../assets/images/under-heading.png" class="img-fluid">
        </div> -->
    </div>
</div>
</template>

<script>
export default {
    name: 'MainHeading',
    props: {
        main_heading: String
    }
}
</script>

<style scoped>
/* ******************************* Main Heading ******************************* */
.section-heading {
    margin-bottom: 60px;
}
.section-heading::before{
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 40px;
    margin: 0 auto;
    z-index: 0;
    height: 10px;
    width: 517px;
    background-repeat: no-repeat;
    background-image: url(../../assets/images/under-heading.png);
    background-position: center;
}
.section-heading h2{
    font-family: 'conthrax', sans-serif  !important;
    font-size: 28px;
}

section.intro_px .section-heading::before {
    background-image: url(../../assets/images/under-heading1.png);
}

section.choose_us .section-heading::before {
    background-image: url(../../assets/images/under-heading1.png);
} 
.intro_px .section-heading h2 ,
.choose_us .section-heading h2{
    color: var(--white);
}

/* ******************************* RESPONSIVE CSS ******************************* */
@media (min-width:320px) and (max-width:767px) {
    .section-heading h2 {
        font-size: 20px;
    }
        .section-heading[data-v-492468a0]::before {
        width: 304px;
        background-repeat: no-repeat;
        background-size: contain;
        left: 0px;
        top: 49px;
    }
}
</style>
